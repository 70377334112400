var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _vm.hasEliteSubscription
          ? _c(
              "div",
              { staticClass: "headerContainer__alignRight" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn--primary btn--sm",
                    attrs: { to: { name: "ServiceNew" } },
                  },
                  [_vm._v("+ ADD Service")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "section",
      {
        staticClass: "section",
        class: {
          "h-mt-160 section--flexCenter":
            _vm.services.length === 0 && _vm.hasEliteSubscription,
        },
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : !_vm.hasEliteSubscription
          ? _c("AlertBoxSubscription")
          : _vm.services.length === 0
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [
                _c("h2", [_vm._v("You haven't created any service yet.")]),
                _c("p", [_vm._v("Get started today!")]),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn--primary",
                    attrs: { to: { name: "ServiceNew" } },
                  },
                  [_vm._v("Add a new Service")]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "container container--fullWidth wrapper--filled" },
              [
                _c(
                  "table",
                  [
                    _vm._m(1),
                    _c("tbody"),
                    _vm._l(_vm.services, function (service) {
                      return _c(
                        "tr",
                        { key: service.id, staticClass: "beat-table" },
                        [
                          _c(
                            "td",
                            [
                              _c("icon", {
                                attrs: { name: service.icon, color: "black" },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticClass: "beat-table__title" }, [
                            _c(
                              "div",
                              { staticClass: "flexbox" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "ServiceEdit",
                                        params: { id: Number(service.id) },
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(service.name))]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "p--lightGrey hideOnMobile" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.descriptionToShow(service.description) +
                                    "..."
                                )
                              ),
                            ]
                          ),
                          _c("td", [_vm._v("$" + _vm._s(service.price))]),
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn--sm btn--black btn--text",
                                  attrs: {
                                    to: {
                                      name: "ServiceEdit",
                                      params: { id: Number(service.id) },
                                    },
                                  },
                                },
                                [_vm._v("EDIT")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h1", [_vm._v("Services")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "beat-table" }, [
        _c("th", { staticClass: "beat-table__image", attrs: { width: "40" } }),
        _c("th", [_vm._v("SERVICE NAME")]),
        _c("th", { staticClass: "hideOnMobile" }, [_vm._v("DESCRIPTION")]),
        _c("th", [_vm._v("price")]),
        _c("th", { attrs: { width: "120" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }